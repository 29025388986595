import React from 'react';
import '../styles/Contact.css';

const Contact = () => {
  return (
    <section id="contact">
      <p>Email: tanjayfoodsab@gmail.com</p>
      <p>Telefon: (000) 000-0000</p>
      <button onClick={() => alert("Kontaktformulär kommer snart!")}>Kontakta Oss</button>
    </section>
  );
};

export default Contact;
