import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/BlogPost.css'; // Assuming you have this file for styling

const blogPosts = [
    {
        id: 1,
        title: "Utforska Exotiska Frukter",
        description: "Upptäck det fantastiska utbudet av tropiska frukter, från mango till litchi, som ger livfulla smaker till din tallrik.",
        fullContent: "Tropiska frukter som mango, papaya, och litchi erbjuder en unik blandning av sötma, syrlighet, och livfulla smaker som inte bara förhöjer " +
            "kulinariska upplevelser utan även ger flera hälsofördelar. \n\n" +
            "Mango, ofta kallad 'frukternas kung', är rik på vitamin C, A och antioxidanter som hjälper till att stärka immunförsvaret och främja frisk hud. " +
            "Den kan användas i sallader, smoothies, chutneys, och även i varma rätter som indiska curryrätter eller som topping på grillade rätter. \n\n" +
            "Papaya är en annan näringsrik frukt som är fylld med enzymet papain, vilket underlättar matsmältningen. Papaya är också känd för sitt höga innehåll " +
            "av fibrer, vitamin A och folsyra, vilket gör den till ett bra tillskott i en hälsosam kost. Den kan användas i tropiska fruktsallader, som juice eller " +
            "i desserter som 'mango sticky rice'. \n\n" +
            "Litchi är en saftig frukt med en blommig smak och har ett högt innehåll av vitamin C och B6 samt viktiga mineraler som koppar och kalium. " +
            "Denna frukt används ofta i efterrätter, glass eller som en uppfriskande ingrediens i cocktails och smoothies. \n\n" +
            "Andra populära exotiska frukter inkluderar passionsfrukt, guava och jackfruit. Passionsfrukt har en intensiv syrlig smak och är fylld med kostfibrer och " +
            "vitamin A och C, vilket gör den perfekt för att förhöja fruktpuréer, bakverk eller drycker. Guava, å andra sidan, är känd för sitt otroligt höga innehåll " +
            "av C-vitamin – upp till fyra gånger så mycket som apelsiner – och används i allt från juice till sorbeter. Jackfruit, en stor, köttig frukt, kan till och " +
            "med användas som ett köttsubstitut i vegetariska och veganska rätter tack vare dess konsistens. \n\n" +
            "Dessa frukter erbjuder inte bara unika smaker och texturer utan även betydande hälsofördelar. De är rika på antioxidanter som bekämpar fria radikaler, " +
            "stödjer hjärthälsan och kan bidra till att sänka kolesterolnivåerna. När du använder exotiska frukter i din matlagning öppnar du upp en värld av nya " +
            "smakkombinationer och näringsmässiga fördelar. Prova att lägga till dem i sallader, juicer, smoothies, desserter eller som tillbehör till dina huvudrätter " +
            "för en frisk och tropisk twist.",
        image: "/Images/blog/Frukt.jpg",
    },
    {
        id: 2,
        title: "Tropiska Matlagningstekniker",
        description: "Lär dig om unika matlagningstekniker som är nyckeln till tropisk mat, som grillning med bananblad.",
        fullContent: "Tropiska matlagningstekniker som grillning med bananblad ger maten en unik och naturlig rökighet. Genom att svepa in kött eller "+
        "fisk i bananblad och sedan grilla dem, bevaras fukten och smaken intensifieras. Denna metod används flitigt i många tropiska kulturer, där den "+
        "är ett sätt att både laga mat och tillföra djupa, rika smaker. Dessutom är bananbladen ett miljövänligt alternativ till plast, vilket gör denna "+
        "teknik både smakfull och hållbar. \n\n" +
        "En annan populär teknik är att laga mat över öppen eld, vilket ger rätterna en rustik och autentisk smak. Att laga över eld är inte bara en praktisk "+
        "metod, utan också en social aktivitet där människor samlas runt elden för att njuta av maten tillsammans. Genom att använda denna teknik kan du få en "+
        "smakfull och aromatisk upplevelse, speciellt när du grillar grönsaker eller kött som marinerats i tropiska kryddor. Det kan också ge en härlig char som "+
        "är svår att återskapa i ett kök. \n\n" +
        "Användningen av specialredskap är också centralt för tropisk matlagning. Många kockar använder gjutjärnspannor, stenugnar eller bambukorgar för att laga "+
        "sina rätter. Dessa verktyg är inte bara traditionella, utan hjälper också till att fördela värmen jämnt, vilket ger en perfekt tillagning. Att utforska "+
        "dessa redskap kan öppna dörrar till nya smakupplevelser och inspirera till kreativitet i köket. \n\n" +
        "Att lära sig dessa tekniker kan verkligen berika din matlagning. Genom att integrera dessa metoder i din egen matlagning kan du få en djupare förståelse "+
        "för den tropiska kulturen och dess gastronomiska traditioner. Oavsett om du grillar med bananblad eller lagar mat över öppen eld, ger dessa metoder en "+
        "möjlighet att skapa minnesvärda måltider som återspeglar rika smaker och kulturella influenser.",
        image: "/Images/blog/TropiskaMatlagningstekniker.jpg",
    },    
    {
        id: 3,
        title: "En Guide till Tropiska Kryddor",
        description: "Lås upp hemligheterna bakom tropiska kryddor som kryddpeppar, muskot och tamarind för att förhöja dina rätter.",
        fullContent: "Tropiska kryddor som kryddpeppar, muskot, och tamarind är oumbärliga i många exotiska kök. \n" +
        "Dessa kryddor bidrar inte bara med smak, utan också med djup och karaktär till en mängd olika rätter. \n" +
        "Kryddpeppar ger en distinkt aromatisk värme som kan lyfta även de enklaste maträtter, och dess komplexa smakprofil gör den till en favorit i marinader. \n" +
        "Muskot används för sin söta och kryddiga smak och kan kombineras med både söta och salta rätter för att ge en extra dimension. \n\n" +
        "Tamarind, å andra sidan, ger en syrlig ton som ofta används i såser och curryrätter för att balansera smaker. \n" +
        "Den unika smaken av tamarind är perfekt för att ge liv åt rätter som köttgrytor och fruktsåser, och den kan också användas i drycker för att tillföra en fräsch syra. \n" +
        "Dessa kryddor är inte bara viktiga för deras smak, utan de har också hälsofördelar; exempelvis är tamarind rik på antioxidanter som kan främja matsmältningen. \n" +
        "Genom att inkludera dessa kryddor i din matlagning kan du förvandla vanliga ingredienser till något extraordinärt. \n\n" +
        "Kryddpeppar och muskot fungerar utmärkt tillsammans i rätter som grytor, där de kan ge en varm och inbjudande smak. \n" +
        "Att experimentera med dessa kryddor kan öppna upp en värld av möjligheter i ditt kök. \n" +
        "Tänk på att börja med små mängder, eftersom deras starka smaker kan överväldiga om de används för mycket. \n" +
        "Genom att förstå kryddornas ursprung och deras traditionella användning i tropiska kök kan du också få en djupare förståelse för kulturen de kommer ifrån. \n\n" +
        "Att lära sig använda dessa kryddor kan verkligen berika dina kulinariska skapelser. \n" +
        "Med en liten insats kan du förvandla dina rätter och ge dem en autentisk tropisk känsla. \n" +
        "Prova att experimentera med kryddpeppar, muskot och tamarind i olika kombinationer, och se hur de kan komplettera varandra i både söta och salta rätter. \n" +
        "Genom att förstå och använda dessa kryddor på rätt sätt kan du höja dina maträtter till nya nivåer och skapa oförglömliga smakupplevelser.",
        image: "/Images/blog/TropiskaKryddor.jpg",
    },
    {
        id: 4,
        title: "Kokosnöten: Den Ultimata Tropiska Ingrediensen",
        description: "Från kokosmjölk till kokosolja, lär dig hur denna mångsidiga ingrediens utgör grunden i tropisk mat.",
        fullContent: "Kokosnöten är kanske den mest mångsidiga ingrediensen i tropisk mat. \n" +
        "Den erbjuder en rad olika smakupplevelser och texturer som kan förvandla vanliga rätter till något extraordinärt. \n" +
        "Kokosmjölk används ofta i soppor, curryrätter och desserter, och ger en krämig konsistens som förhöjer smaken. \n" +
        "Dess rika och fylliga smak gör den till en favorit i många sydostasiatiska och karibiska rätter. \n\n" +
        "Kokosolja, å sin sida, är perfekt för stekning och bakning. \n" +
        "Den höga rökpunkten gör den idealisk för att laga mat på hög värme, och dess smak tillför en subtil tropisk känsla till rätter. \n" +
        "Kokosolja är också en populär ingrediens i hälsosamma recept och används ofta som ett alternativ till smör eller andra oljor. \n" +
        "Det är en utmärkt källa till energi och innehåller medellånga fettsyror som kan ge hälsosamma fördelar. \n\n" +
        "Riven kokos ger rätter en härlig textur och sötma. +\n" +
        "Den kan användas i bakverk, smoothies, och till och med som topping på olika rätter för att ge en extra crunch. \n" +
        "Kokoschips är också ett populärt snacks som ger en smakfull och krispig upplevelse. \n" +
        "Därutöver används kokosvatten som en uppfriskande dryck, rik på elektrolyter, vilket gör den idealisk för hydrering. \n\n" +
        "Lär dig alla sätt att använda denna fantastiska frukt i ditt kök, från traditionella rätter till moderna skapelser. \n" +
        "Genom att experimentera med kokosnötens olika former kan du skapa smakfulla och näringsrika måltider. \n" +
        "Att använda kokos i matlagningen ger inte bara god smak utan också en tropisk känsla som för tankarna till varma stränder. \n" +
        "Oavsett om du är en erfaren kock eller nybörjare, kommer kokosnöten att bli en oumbärlig del av din matlagning.",
        image: "/Images/blog/Cocos.jpg",
    },
    {
        id: 5,
        title: "Uppfriskande Tropiska Drycker",
        description: "Sippa på uppfriskande tropiska drycker som piña colada, kokosvatten och exotiska frukt smoothies.",
        fullContent: "Tropiska drycker som piña colada, kokosvatten och olika fruktsmoothies är perfekta för att svalka sig under varma dagar. \n" +
        "Dessa drycker erbjuder en explosion av smak och är en njutning för både öga och gom. \n" +
        "Piña colada, med sin kombination av ananas och kokos, är en klassiker som tar dig direkt till tropikerna. \n" +
        "Det är en perfekt cocktail för att njuta av på stranden eller vid poolen, och kan enkelt göras utan alkohol för en familjevänlig version. \n\n" +
        "Kokosvatten är naturligt hydratiserande och fyllt med elektrolyter, vilket gör det till ett utmärkt val för att återställa vätskebalansen efter en varm dag. \n" +
        "Det är en lätt och uppfriskande dryck som är både smaklig och nyttig. \n" +
        "Oavsett om det dricks rent eller används som bas i smoothies och cocktails, är kokosvatten ett måste i varje tropisk dryckesrepertoar. \n" +
        "Dess sötma och krispiga konsistens gör det till en populär ingrediens i många recept. \n\n" +
        "Smoothies gjorda på färsk mango, ananas och papaya ger en näringsrik och läcker start på dagen. \n" +
        "Genom att kombinera dessa frukter med lite yoghurt eller mjölk får du en krämig och uppfriskande dryck som är fullproppad med vitaminer. \n" +
        "Smoothies är också ett utmärkt sätt att öka ditt intag av frukt och grönsaker, vilket gör dem till en idealisk frukost eller mellanmål. \n" +
        "Prova att lägga till spenat eller avokado för en extra näringsboost. \n\n" +
        "Lär dig att blanda dessa drycker och njut av de tropiska smakerna. \n" +
        "Att experimentera med olika kombinationer av frukter och kryddor kan leda till nya favoritdrycker. \n" +
        "Tillsätt mynta eller basilika för en fräsch twist, eller använd limejuice för att ge en syrlig dimension. \n" +
        "Oavsett vilket du väljer, kommer dessa drycker att ge dig en känsla av semester varje gång du tar en sipp.",
        image: "/Images/blog/Drycker.jpg",
    },
    {
        id: 6,
        title: "Kryddiga Tropiska Curryrätter",
        description: "Utforska den smakrika och kryddiga världen av tropiska curryrätter, fyllda med unika smaker och hetta.",
        fullContent: "Tropiska curryrätter är kända för sina komplexa smaker och intensiva kryddor.\n" +
            "Dessa rätter erbjuder en fantastisk blandning av olika smakprofiler, vilket gör dem till en fest för smaklökarna.\n" +
            "Rätter som karibisk jerk chicken curry och thailändsk röd curry kombinerar aromatiska kryddor med färska örter och kokosmjölk\n" +
            "för att skapa en balanserad och fyllig smakprofil.\n\n" +
            "Karibisk jerk chicken curry är en av de mest älskade rätterna och är känd för sin rökiga hetta och djupa smaker.\n" +
            "Kryddorna, inklusive kryddpeppar, kanel och muskot, ger en unik karaktär som gör att denna rätt verkligen står ut.\n" +
            "Det är en perfekt maträtt för grillning, där kycklingen marineras i en smakrik blandning och sedan grillas till perfektion.\n" +
            "Servera med ris eller bröd för att fånga upp den läckra såsen.\n\n" +
            "Thailändsk röd curry, å sin sida, är en annan ikonisk rätt som är älskad världen över.\n" +
            "Med sin krämiga konsistens och djupa smakprofil, kommer den från en bas av röd currypasta som kombineras med kokosmjölk.\n" +
            "Den är ofta fylld med grönsaker och kött eller tofu, vilket gör den till en mångsidig rätt som kan anpassas efter din smak.\n" +
            "Lägg till färska örter som basilika och koriander för att ge extra smak och färg.\n\n" +
            "Oavsett om du föredrar milda eller eldiga curryrätter, finns det något för alla.\n" +
            "Mildare varianter kan lätt göras genom att justera mängden kryddor, så att även de som inte gillar stark mat kan njuta av dessa rätter.\n" +
            "Att utforska tropiska curryrätter är ett utmärkt sätt att upptäcka nya smaker och traditioner.\n" +
            "Varje rätt berättar en historia och erbjuder en smakfull resa genom tropikernas kulinariska landskap.",    
        image: "/Images/blog/TropicalCurry.jpg",
    },
    {
        id: 7,
        title: "Tropiska Desserter Du Måste Prova",
        description: "Njut av söta godsaker som tropiska frukttårtor, kokosmakroner och mango sticky rice.",
        fullContent: "Tropiska desserter är en njutning för både ögat och smaklökarna.\n" +
            "De erbjuder en rad olika smaker och texturer som verkligen fångar essensen av tropikerna.\n" +
            "Tänk dig frukttårtor toppade med färska mango- och passionsfrukter, där den klara färgen av frukterna\n" +
            "skapar en visuell fest. Dessa tårtor är ofta lätta och luftiga, vilket gör dem till den perfekta avslutningen på en måltid.\n\n" +
            "Kokosmakroner är en annan klassiker som du inte får missa.\n" +
            "De är gjorda på riven kokos och har en söt och seg konsistens som smälter i munnen.\n" +
            "Dessa små godsaker är perfekta för att tillfredsställa ditt sötsug och passar utmärkt tillsammans med en kopp te eller kaffe.\n" +
            "Dessutom kan de enkelt anpassas med olika smaker, som choklad eller vanilj.\n\n" +
            "En annan ikonisk dessert är den thailändska klassikern mango sticky rice, där sötman av kokosmjölk\n" +
            "smälter ihop med den saftiga smaken av mogen mango.\n" +
            "Denna rätt serveras vanligtvis kall och är en perfekt kombination av sött och krämigt.\n" +
            "Att njuta av mango sticky rice på en varm dag är verkligen en lyx.\n\n" +
            "Dessa desserter tar dig direkt till tropikerna och är ett utmärkt sätt att avsluta en måltid.\n" +
            "De erbjuder en smakfull och färgglad presentation som gör dem till stjärnorna på dessertbordet.\n" +
            "Oavsett om du är i tropikerna eller hemma, är det lätt att skapa dessa läckerheter och\n" +
            "ge dina gäster en smakfull upplevelse av tropisk njutning.",
        image: "/Images/blog/deserts.jpg"
    },    
    {
        id: 8,
        title: "Hälsosamma Tropiska Sallader",
        description: "Upptäck livfulla och uppfriskande tropiska sallader fyllda med färska ingredienser som papaya, ananas och avokado.",
        fullContent: "Tropiska sallader är fyllda med färska och näringsrika ingredienser som gör dem både smakliga och hälsosamma.\n" +
        "De erbjuder en fantastisk möjlighet att kombinera olika smaker och texturer för en uppfriskande måltid.\n" +
        "Papaya- och mangosallader kombinerar sötma och syrlighet på ett sätt som verkligen tillfredsställer smaklökarna.\n" +
        "Genom att tillsätta några skivor lime och en nypa chili får du en extra dimension av smak som lyfter rätten.\n\n" +
        "Ananas och avokado är andra populära ingredienser i tropiska sallader.\n" +
        "Ananas tillför en söt och saftig smak, medan avokado ger en krämig konsistens som gör salladen mer tillfredsställande.\n" +
        "Dessa ingredienser fungerar också bra tillsammans med olika typer av gröna blad, som spenat eller ruccola.\n" +
        "Att använda färska örter som mynta eller koriander kan ytterligare förstärka smakerna och ge en aromatisk touch.\n\n" +
        "Salladerna kan enkelt anpassas efter säsongens bästa råvaror, vilket gör dem till en mångsidig rätt.\n" +
        "Lägg till nötter eller frön för extra crunch och protein, eller inkludera grönsaker som paprika och gurka för mer färg.\n" +
        "En dressing gjord på olivolja och balsamvinäger eller en lime- och chilidressing passar perfekt för att knyta ihop smakerna.\n" +
        "Dessa sallader är inte bara läckra utan också fulla av vitaminer och mineraler, vilket gör dem till ett utmärkt val för en hälsosam kost.\n\n" +
        "Oavsett om det är som en lätt lunch eller som tillbehör till en grillmiddag, är tropiska sallader en fantastisk möjlighet att njuta av\n" +
        "de färska och livliga smakerna från tropikerna.\n" +
        "De är lätta att förbereda och perfekt för varma sommardagar, vilket gör dem till en oumbärlig del av varje matbord.",
        image: "/Images/blog/Sallad.jpg"
    },    
    {
        id: 9,
        title: "Gatumat från Tropikerna",
        description: "Ge dig ut på en kulinarisk resa genom tropikernas livliga gator och upptäck otrolig gatumat.",
        fullContent: "Gatumat från tropikerna erbjuder en explosion av smaker som verkligen fångar essensen av varje region.\n" +
        "Från karibiska fisk tacos till thailändska satay och färska fruktspett, varje plats har sina unika specialiteter.\n" +
        "Denna typ av mat är inte bara ett sätt att äta, utan också en del av kulturen och livsstilen i dessa länder.\n" +
        "Att njuta av gatumat är en upplevelse som involverar dofter, smaker och atmosfären av livliga gator.\n\n" +
        "Många gatumatstånd tillagar sina rätter över öppen eld, vilket ger maten en rökig och autentisk smak.\n" +
        "Det är också vanligt att se kockar som arbetar i full sving, vilket ger en känsla av energi och spänning.\n" +
        "Serveringen är ofta snabb och effektiv, vilket gör att du kan prova många olika rätter under en och samma måltid.\n" +
        "Det är en fantastisk möjlighet att upptäcka lokala ingredienser och smaker som du kanske aldrig har provat tidigare.\n\n" +
        "Färska ingredienser och intensiv kryddning gör att varje tugga är en ny upplevelse.\n" +
        "Kryddor som ingefära, vitlök och chili används ofta för att förstärka smakerna och ge rätterna en unik hetta.\n" +
        "Det finns också ett stort utbud av vegetariska och veganska alternativ, vilket gör gatumat tillgänglig för alla.\n" +
        "Prova olika såser och toppings för att skapa din perfekta kombination.\n\n" +
        "Att utforska gatumat är ett utmärkt sätt att lära känna en kulturs hjärta och själ.\n" +
        "Det är också en social upplevelse, där du kan dela rätter med vänner eller nya bekanta.\n" +
        "Så nästa gång du är i en tropisk destination, missa inte chansen att njuta av denna smakfulla och levande mattradition.",
        image: "/Images/blog/gatumat.jpg"
    },    
    {
        id: 10,
        title: "Matlagning med Tropisk Fisk",
        description: "Lär dig hur man tillagar färsk tropisk fisk som mahi-mahi, snapper och tonfisk för läckra ö-inspirerade rätter.",
        fullContent: "Tropisk fisk som mahi-mahi, snapper och tonfisk är perfekta för grillning och snabb tillagning.\n" +
            "Dessa fiskar är rika på omega-3, vilket gör dem inte bara läckra utan också hälsofrämjande.\n" +
            "Deras fasta kött gör dem idealiska för rätter som fisktacos, ceviche eller grillad fisk med citrusmarinader.\n" +
            "Denna typ av fisk absorberar smaker väl, vilket gör att marinader och kryddor verkligen kan lysa igenom.\n\n" +
            "När du lagar tropisk fisk är det viktigt att använda färska ingredienser för att få fram de bästa smakerna.\n" +
            "Att grilla fisken med färska örter och citrusfrukter, som lime eller citron, ger en härlig syra som balanserar rätterna.\n" +
            "Du kan också experimentera med olika marinader, inklusive sojasås, ingefära och vitlök för att skapa djupa, komplexa smaker.\n" +
            "Fiskens naturliga smak gör att den kan stå i centrum på tallriken, så håll tillbehören enkla och fräscha. +\n\n" +
            "Ceviche är en fantastisk rätt som verkligen framhäver den tropiska fisken.\n" +
            "Den tillagas genom att marinera fisken i citrusjuice, vilket “tillagar” den utan värme och ger en fräsch smak.\n" +
            "Tillsätt färska grönsaker som rödlök, koriander och jalapeño för extra textur och smak.\n" +
            "Servera med tortilla chips eller på en säng av grönsallad för en lätt och uppfriskande måltid. \n\n" +
            "Grillad tropisk fisk kan enkelt tillagas på en utegrill eller i ugnen, vilket gör det till en perfekt rätt för sommarens middagar. \n" +
            "Det är också en utmärkt möjlighet att inkludera säsongens grönsaker, som zucchini eller paprika, som sidorätter. \n" +
            "Att laga fisk på detta sätt gör den både smakrik och hälsosam, perfekt för hela familjen. \n" +
            "Så, oavsett om du lagar en festmåltid eller en enkel middag, glöm inte att utforska de fantastiska smakerna av tropisk fisk.",
        image: "/Images/blog/fiskstekt.jpg"
    },    
];

const BlogPost = () => {
    const { id } = useParams();
    const post = blogPosts.find((post) => post.id === parseInt(id));
    const navigate = useNavigate(); // Hook to navigate programmatically

    if (!post) {
        return <h2>Inlägg hittades inte</h2>;
    }

  // Split the full content by line breaks or a delimiter to create paragraphs
  const paragraphs = post.fullContent.split('\n\n');

  return (
    <div className="post-detail">
      <img src={post.image} alt={post.title} className="post-image" />
      <h1 className="post-title">{post.title}</h1>
      
      {/* Render each paragraph separately */}
      {paragraphs.map((paragraph, index) => (
        <p key={index} className="post-content">
          {paragraph}
        </p>
      ))}

      {/* Go Back Button */}
      <button className="go-back-button" onClick={() => navigate(-1)}>
        Tillbaka till bloggen
      </button>
    </div>
  );
};

export default BlogPost;