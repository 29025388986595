import React from 'react';
import '../styles/About.css';

const About = () => {
  return (
    <section id="about">
      <p className='aboutText'>Välkommen till Tanjay, där vi serverar den bästa tropiska maten med en touch av tradition och modernitet!</p>
    </section>
  );
};

export default About;
