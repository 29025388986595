const menuData = {
  forratter: [
    { name: 'Samosa', price: 50, description: 'Friterade degknyten fyllda med kryddig potatis och ärtor.' },
    { name: 'Paneer Tikka', price: 80, description: 'Marinerad paneer (indisk färskost) grillad till perfektion.' },
    { name: 'Onion Bhaji', price: 45, description: 'Krispiga friterade lökringar serverade med chutney.' },
    { name: 'Papadum', price: 20, description: 'Tunna krispiga bröd serverade med chutney.' },
    { name: 'Tandoori Chicken Starter', price: 60, description: 'Kyckling marinerad i tandoori-kryddor.' },
    { name: 'Vegetable Pakora', price: 40, description: 'Friterade grönsaker doppade i kryddig kikärtsmjölssmet.' },
    { name: 'Fish Cutlet (Sri Lanka)', price: 55, description: 'Kryddiga tonfiskkakor serverade med chili-dip.' },
    { name: 'Masala Vadai', price: 35, description: 'Krispiga linser kakor kryddade med koriander och chili.' },
    { name: 'Pol Sambol', price: 30, description: 'Kokosröra med chili och lime, en perfekt förrätt från Sri Lanka.' },
    { name: 'Tropical Avocado Salad', price: 50, description: 'Fräsch avokadosallad med mango och chili-dressing.' },
    { name: 'Coconut Shrimp', price: 75, description: 'Friterade räkor täckta med kokosflakes, serverade med söt chilisås.' },
    { name: 'Pineapple Salsa Tacos', price: 60, description: 'Tacos med tropisk ananas- och tomatsalsa.' },
    { name: 'Mango Chutney Crostini', price: 40, description: 'Krispigt bröd toppat med söt mangochutney och mynta.' },
  ],
  huvudratter: [
    { name: 'Biryani', price: 100, description: 'En kryddig risrätt tillagad med kyckling, lamm eller grönsaker.' },
    { name: 'Butter Chicken', price: 120, description: 'Kyckling tillagad i en krämig tomat- och smörsås.' },
    { name: 'Chicken Tikka Masala', price: 130, description: 'Kycklingbitar i en smakrik tomatsås med indiska kryddor.' },
    { name: 'Palak Paneer', price: 90, description: 'Paneer (indisk färskost) kokt i en kryddig spenatsås.' },
    { name: 'Chana Masala', price: 85, description: 'En kikärtsgryta tillagad med tomat och garam masala.' },
    { name: 'Lamb Rogan Josh', price: 140, description: 'Lamm i en kryddig kashmirisk sås med yoghurt och kryddor.' },
    { name: 'Aloo Gobi', price: 80, description: 'En mild curry gjord med potatis och blomkål.' },
    { name: 'Kottu Roti (Sri Lanka)', price: 115, description: 'Finhackad roti med kyckling eller grönsaker, blandad med kryddor.' },
    { name: 'Hoppers (Sri Lanka)', price: 90, description: 'Traditionella pannkakor med en krispig kant och mjuk mitt, serveras med curry.' },
    { name: 'Devilled Chicken (Sri Lanka)', price: 130, description: 'Het stekt kyckling med paprika och chili.' },
    { name: 'Malai Kofta', price: 100, description: 'Grönsaksbollar i en krämig cashew- och tomatsås.' },
    { name: 'Dal Tadka', price: 85, description: 'Gula linser med stekta kryddor, serveras med ris.' },
    { name: 'Prawn Curry', price: 150, description: 'Räkor tillagade i en kokos- och tomatbaserad curry.' },
    { name: 'Vindaloo', price: 130, description: 'En het curry från Goa tillagad med kött eller grönsaker.' },
    { name: 'Pineapple Fried Rice', price: 90, description: 'Stekt ris med ananas, grönsaker och valfritt kött.' },
    { name: 'Coconut Fish Curry', price: 140, description: 'Fisk tillagad i en krämig kokosbaserad curry.' },
    { name: 'Tropical Grilled Chicken', price: 130, description: 'Grillad kyckling med tropisk fruktsalsa.' },
    { name: 'Mango Coconut Chicken', price: 135, description: 'Kyckling i en mango- och kokosbaserad sås.' },
    { name: 'Tropical Veggie Stir-fry', price: 90, description: 'Grönsaker wokade med ananas och kokosmjölk.' },
  ],
  efterratter: [
    { name: 'Gulab Jamun', price: 50, description: 'Söta mjölkdumplings dränkta i sockerlag smaksatt med kardemumma.' },
    { name: 'Kheer', price: 40, description: 'Indisk rispudding tillagad med mjölk, socker och kardemumma.' },
    { name: 'Rasmalai', price: 55, description: 'Mjuka ostkakor i en söt och krämig mjölksås.' },
    { name: 'Jalebi', price: 45, description: 'Frasiga, friterade snurror doppade i sockerlag.' },
    { name: 'Kulfi', price: 60, description: 'Traditionell indisk glass med smaker som mango och pistasch.' },
    { name: 'Gajar Halwa', price: 50, description: 'En söt morotsbaserad efterrätt tillagad med mjölk och ghee.' },
    { name: 'Watalappan (Sri Lanka)', price: 65, description: 'En krämig kokosbaserad pudding med smak av kanel och kardemumma.' },
    { name: 'Pineapple Kesari (Sri Lanka)', price: 45, description: 'Söt mannagrynsdessert med ananas och cashewnötter.' },
    { name: 'Tropical Fruit Salad', price: 55, description: 'Färsk tropisk fruktsallad med mango, ananas och passionsfrukt.' },
    { name: 'Coconut Panna Cotta', price: 65, description: 'En len och krämig panna cotta smaksatt med kokos.' },
    { name: 'Mango Sorbet', price: 50, description: 'Uppfriskande mangosorbet med en touch av lime.' },
  ],
};

export default menuData;
