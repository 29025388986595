import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Blog.css'; // Optional: for styling

const blogPosts = [
  {
    id: 1,
    title: "Utforska Exotiska Frukter",
    description: "Upptäck det fantastiska utbudet av tropiska frukter, från mango till litchi, som ger livfulla smaker till din tallrik.",
    image: "/Images/blog/Frukt.jpg",
  },
  {
    id: 2,
    title: "Tropiska Matlagningstekniker",
    description: "Lär dig om unika matlagningstekniker som är nyckeln till tropisk mat, som grillning med bananblad.",
    image: "/Images/blog/TropiskaMatlagningstekniker.jpg",
  },
  {
    id: 3,
    title: "En Guide till Tropiska Kryddor",
    description: "Lås upp hemligheterna bakom tropiska kryddor som kryddpeppar, muskot och tamarind för att förhöja dina rätter.",
    image: "/Images/blog/TropiskaKryddor.jpg",
  },
  {
    id: 4,
    title: "Kokosnöten: Den Ultimata Tropiska Ingrediensen",
    description: "Från kokosmjölk till kokosolja, lär dig hur denna mångsidiga ingrediens utgör grunden i tropisk mat.",
    image: "/Images/blog/Cocos.jpg",
  },
  {
    id: 5,
    title: "Uppfriskande Tropiska Drycker",
    description: "Sippa på uppfriskande tropiska drycker som piña colada, kokosvatten och exotiska frukt smoothies.",
    image: "/Images/blog/Drycker.jpg",
  },
  {
    id: 6,
    title: "Kryddiga Tropiska Curryrätter",
    description: "Utforska den smakrika och kryddiga världen av tropiska curryrätter, fyllda med unika smaker och hetta.",
    image: "/Images/blog/TropicalCurry.jpg",
  },
  {
    id: 7,
    title: "Tropiska Desserter Du Måste Prova",
    description: "Njut av söta godsaker som tropiska frukttårtor, kokosmakroner och mango sticky rice.",
    image: "/Images/blog/deserts.jpg",
  },
  {
    id: 8,
    title: "Hälsosamma Tropiska Sallader",
    description: "Upptäck livfulla och uppfriskande tropiska sallader fyllda med färska ingredienser som papaya, ananas och avokado.",
    image: "/Images/blog/Sallad.jpg",
  },
  {
    id: 9,
    title: "Gatumat från Tropikerna",
    description: "Ge dig ut på en kulinarisk resa genom tropikernas livliga gator och upptäck otrolig gatumat.",
    image: "/Images/blog/gatumat.jpg",
  },
  {
    id: 10,
    title: "Matlagning med Tropisk Fisk",
    description: "Lär dig hur man tillagar färsk tropisk fisk som mahi-mahi, snapper och tonfisk för läckra ö-inspirerade rätter.",
    image: "/Images/blog/fiskstekt.jpg",
  },
];

const Blog = () => {
  return (
    <div className="blog-section">
      <h2>Upptäck Tropiska Smaker</h2>
      <div className="blog-posts">
        {blogPosts.map((post) => (
          <div key={post.id} className="blog-post">
            <img src={post.image} alt={post.title} className="blog-image" />
            <h3>{post.title}</h3>
            <p>{post.description}</p>
            <Link to={`/blog/${post.id}`} className="read-more">
              Läs mer
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;