import React from 'react';
import menuData from '../data/menuData'; // Import the menu data
import '../styles/Menu.css';

const Menu = () => {
  return (
    <section id="menu">
      {/* Starters - Förrätter */}
      <h3 className='menuTittle' >Förrätter</h3>
      <ul>
        {menuData.forratter.map((item, index) => (
          <li key={index}>
            {item.name} - {item.price}
            <p>{item.description}</p>
          </li>
        ))}
      </ul>

      {/* Main Courses - Huvudrätter */}
      <h3 className='menuTittle' >Huvudrätter</h3>
      <ul>
        {menuData.huvudratter.map((item, index) => (
          <li key={index}>
            {item.name} - {item.price}
            <p>{item.description}</p>
          </li>
        ))}
      </ul>

      {/* Desserts - Efterrätter */}
      <h3 className='menuTittle' >Efterrätter</h3>
      <ul>
        {menuData.efterratter.map((item, index) => (
          <li key={index}>
            {item.name} - {item.price}
            <p>{item.description}</p>
          </li>
        ))}
      </ul>
      
      <button onClick={() => alert("Det är inte möjligt att beställa nu, Vi öppnar Januari 2025")}>Beställ</button>
    </section>
  );
};

export default Menu;
